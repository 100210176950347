<input class="modal-state" [checked]="!!data" id="confirm-modal-project" type="checkbox"/>
<div class="modal">
  <div class="modal-content p-0 flex flex-col gap-5">
    <div class="modal-header">
      <h2 class="text-xl"> {{data.header}} </h2>
      <label
        for="confirm-modal-project"
        (click)="close(null)"
        class="btn btn-sm btn-circle btn-ghost right-2 top-0 mr-[1.5rem] ml-[0.5rem]"
      >✕</label
      >
    </div>
    <div class="divider divider-horizontal"></div>
    <div class="modal-body">
      <div class="form-field">
        <label class="form-label"> {{data.text}} </label>
        <label class="sub-title4">{{data.subText}}</label>
      </div>

      <div class="form-field">
        <input [placeholder]="placeholder" type="text" value="Test" class="input max-w-full"
               [formControl]="formCtrl"/>
      </div>
    </div>

    <div class="divider divider-horizontal"></div>
    <div class="modal-footer">
      <button (click)="close(null)" class="btn btn-outline-secondary btn-sm cancelBtn hover:bg-error">
        <label for="confirm-modal-project" class="cursor-pointer visually-hidden">{{cancelActionName}}</label>
        {{cancelActionName}}
      </button>
      <button class="btn btn-primary btn-sm saveBtn" (click)="onSubmit()">
        <label for="confirm-modal-project" class="cursor-pointer visually-hidden">{{confirmActionName}}</label>
        {{confirmActionName}}
      </button>
    </div>
  </div>
</div>
