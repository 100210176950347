<input class="modal-state" [checked]="!!data" id="confirm-modal-project" type="checkbox"/>
<div class="modal">
  <div class="modal-content p-0 flex flex-col gap-5 w-[500px] h-[150px]">
    <div class="modal-header">
      <div class="flex flex-row items-center">
        <i class="ph ph-cloud-x mr-4"></i>
        <h2 class="text-xl">{{errorHeaders[data.error.status]}}</h2>
      </div>
      <label
        (click)="close(null)"
        class="btn btn-sm btn-circle btn-ghost right-2 top-0 mr-[1.5rem] ml-[0.5rem]"
      >✕</label
      >
    </div>
    <div class="divider divider-horizontal"></div>
    <div class="modal-body h-[250px]">
      <p class="sub-title4">{{errorMessage}}</p>
    </div>
  </div>
</div>
