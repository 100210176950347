import {Injectable} from '@angular/core';
import {User} from '../models/users/user';
import {RoleGuard} from './role-guard.service';
import {LoginResponse} from '../models/users/login-response';

@Injectable()
export class AdminGuard extends RoleGuard {
  userHasRole(loginResponse: LoginResponse): boolean {
    return loginResponse.isAuth && loginResponse.user.isAdmin;
  }
}
