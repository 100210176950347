import {Component, ComponentRef} from '@angular/core';

@Component({
  selector: 'app-base-modal',
  template: '',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent<T, S> {
  id: string = '';
  protected data: T;
  private ref: ComponentRef<BaseModalComponent<T, S>>;
  private resolve: (value: S) => void;


  public async init(data: T) {
    this.data = data;
    await this.onOpen();
  }

  public async setRefAndResolve(ref: ComponentRef<BaseModalComponent<T, S>>, resolve: (value: S) => void) {
    this.ref = ref;
    this.resolve = resolve;
  }

  public async close(result: S) {
    await this.onClose();
    this.ref?.destroy();
    this.resolve(result);
  }

  public async onOpen() {
  }

  public async onClose() {
  }
}
