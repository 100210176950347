import {Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class TranslatorControllerService {
  public supportedLanguages = {
    'en': 'English',
    'fr': 'Français',
    'es': 'Español',
    'hi': 'हिन्दी',
    'fil': 'Filipino',
    'de': 'Deutsch',
  }
  private readonly _currentLang = new BehaviorSubject<string>('en');
  readonly currentLang$ = this._currentLang.asObservable();

  constructor(
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._currentLang.next(event.lang);
    });
  }


  init() {
    this.translate.addLangs(Object.keys(this.supportedLanguages));
    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      this.translate.use(savedLang);
    }
    else {
      this.translate.setDefaultLang('en');
    }
  }

  public instantTranslate(key: string): string {
    return this.translate.instant(key);
  }

  public switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }

}
