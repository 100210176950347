import {Injectable} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class EventsAnalyticsService {
  SEND_LINKEDIN_ANALYTICS = true;

  startTimeStr: string = null;

  constructor(private router: Router, private http: HttpClient) {
    this.startTimeStr = moment().format('YYYYMMDDHHmmssSSS');
  }

  public registerRouter(): void {
    this.router.events.subscribe(event => {
      if (!(event instanceof ActivationEnd)) {
        return
      }
      if (!('snapshot' in event)) {
        return
      }
      if (!('data' in event.snapshot)) {
        return
      }
      if (!('routeName' in event.snapshot.data)) {
        return
      }
      this.sendEvent(`visit_page_${event.snapshot.data.routeName}`, {});
    });
  }

  public async sendEvent(name: string, attributes: object): Promise<void> {
    const tabID = this.startTimeStr;
    await this.http.post('/api/user-events/', {
        'name': name,
        'tab_id': tabID,
        'attributes': attributes
      },
    ).toPromise();
  }

  public async sendExpertVisitedOnboardingEvent(): Promise<void> {
    if (this.SEND_LINKEDIN_ANALYTICS) {
      // @ts-ignore
      window.lintrk('track', {conversion_id: 13004084});
    }
  }

  public async sendExpertFinishedOnboarding(): Promise<void> {
    if (this.SEND_LINKEDIN_ANALYTICS) {
      // @ts-ignore
      window.lintrk('track', {conversion_id: 13407620});
    }
  }
}
