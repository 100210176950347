import {NgModule} from '@angular/core';
// import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {RouterModule} from '@angular/router';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ExpertComponent} from './components/expert-components/expert/expert.component';
import {DoesntExistYetComponent} from './components/doesnt-exist-yet/doesnt-exist-yet.component';
import {
  EditDegreeDialogComponent
} from './components/expert-components/expert/edit-degree-dialog/edit-degree-dialog.component';
import {
  EditMainInfoDialogComponent
} from './components/expert-components/expert/edit-main-info-dialog/edit-main-info-dialog.component';
import {RatingBarComponent} from './components/rating-bar/rating-bar.component';
import {RepeatPipe} from './pipes/repeat.pipe';
import {OrgLogoComponent} from './components/picutres/org-logo/org-logo.component';
import {ProjectImageComponent} from './components/picutres/project-image/project-image.component';
import {UserPictureComponent} from './components/picutres/user-picture/user-picture.component';
import {ReversePipe} from './pipes/reverse.pipe';
import {JsonDisplayPipe} from './pipes/json-display.pipe';
import {DonutChartComponent} from './components/text-components/donut-chart/donut-chart.component';
import {LocationsMapComponent} from './components/locations-map/locations-map.component';
import {MatchRateComponent} from './components/expert-components/match-rate/match-rate.component';
import {CallsListComponent} from './components/calls/calls-list/calls-list.component';
import {
  ScreeningQuestionsViewerComponent
} from './components/expert-components/screening-questions-viewer/screening-questions-viewer.component';
import {HistoryListComponent} from './components/expert-components/history-list/history-list.component';
import {EmptyUserPictureComponent} from './components/picutres/empty-user-picture/empty-user-picture.component';
import {SliderComponent} from './components/slider/slider.component';
import {SiteFooterComponent} from './components/site-footer/site-footer.component';
import {GenericPaginatorComponent} from './components/generic-tables/generic-paginator/generic-paginator.component';
import {GenericTableComponent} from './components/generic-tables/generic-table/generic-table.component';
import {
  CallsScheduledDoneListsComponent
} from './components/calls/calls-scheduled-done-lists/calls-scheduled-done-lists.component';
import {StatusItemComponent} from './components/status-bars/status-item/status-item.component';
import {CustomTooltipComponent} from './components/text-components/custom-tooltip/custom-tooltip.component';
import {ToolTipDirective} from './components/text-components/custom-tooltip/tooltip.directive';
import {EngagementStatusComponent} from './components/status-bars/engagement-status/engagement-status.component';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {ToggleListComponent} from './inputs/toggle-list/toggle-list.component';
import {ProgressBarComponent} from './components/progress-bars/progress-bar/progress-bar.component';
import {ComplianceTextComponent} from "./components/expert-components/compliance-text/compliance-text.component";
import {GeographyDisplayPipe} from './pipes/geography-display.pipe';
import {SurvivalDisplayPipe} from "./pipes/survival-display.pipe";
import {DragAndDropFileComponent} from "./components/drag-and-drop-file/drag-and-drop-file.component";
import {FileNameDisplayPipe} from './pipes/file-name-display.pipe';
import {LottieModule} from "ngx-lottie";
import {NumberedProgressBarComponent} from './components/numbered-progress-bar/numbered-progress-bar.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {LoginWithLinkedinComponent} from "./login-with-linkedin/login-with-linkedin.component";
import {NgIconComponent} from "@ng-icons/core";
import {MatTableModule} from "@angular/material/table";
import {TimezoneAwareDateDisplayPipe} from './pipes/timezone-aware-date-display.pipe';
import {TimezoneAwareTimeDisplayPipe} from './pipes/timezone-aware-time-display.pipe';
import {TranslateModule} from "@ngx-translate/core";
import {LanguageSelectorImagePipe} from './pipes/language-selector-image.pipe';
import {TaskTrackerArgumentVisualizePipe} from './pipes/task-tracker-argument-visualize.pipe';
import {PascalCaseToReadablePipe} from './pipes/pascal-case-to-readable.pipe';
import {LatestQuestionChangeLogPipe} from './pipes/latest-question-change-log.pipe';
import {CapitalizeFirstLetterPipe} from './pipes/capitalize-first-letter.pipe';
import {GetPercentOutOfWholePipe} from './pipes/get-percent-out-of-whole.pipe';
import {SkeletionTableComponent} from './components/skeletion-table/skeletion-table.component';
import {FormLocationSelectorComponent} from './components/form-location-selector/form-location-selector.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {BadgeComponent} from './components/badge/badge.component';
import {NavTabComponent} from './components/tabs/nav-tab/nav-tab.component';
import {AvatarComponent} from './components/picutres/avatar/avatar.component';
import {ConfirmationModalComponent} from './modals/general-modals/confirmation-modal/confirmation-modal.component';
import {GetTeamMembersFromSubProjectPipe} from './pipes/get-team-members-from-sub-project.pipe';
import {
  SignatureFileUploadModalComponent
} from "./modals/files-modals/signature-file-upload-modal/signature-file-upload-modal.component";
import {RelativeTimePipe} from './pipes/relative-time.pipe';
import {StepProgressComponent} from "./components/step-progress/step-progress.component";
import {
  CreateSubProjectModalComponent
} from './modals/sub-projects-modals/create-sub-project-modal/create-sub-project-modal.component';
import {TextToHtmlPipe} from './pipes/text-to-html.pipe';
import {KababCaseToReadableTextPipe} from './pipes/snake-case-to-readable-text.pipe';
import {NgxSliderModule} from "ngx-slider-v2";
import {CalendarViewComponent} from "./calendar/calendar-view/calendar-view.component";
import {
  CalendarSupportingModalsComponent
} from "./calendar/calendar-supporting-modals/calendar-supporting-modals.component";
import {RadialProgressComponent} from "./components/radial-progress/radial-progress.component";
import {AlertBannerComponent} from './components/alert-banner/alert-banner.component';
import {BaseComponent} from './components/base-component';
import {BasePageComponent} from './components/base-page-component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {LoaderComponent} from './components/loader/loader.component';
import {FileUploadModalComponent} from './modals/files-modals/file-upload-modal/file-upload-modal.component';
import {QuestionMarkTooltipComponent} from './components/question-mark-tooltip/question-mark-tooltip.component';
import {OutlinedIconButtonComponent} from './components/buttons/outlined-icon-button/outlined-icon-button.component';
import {InterviewCardComponent} from "./components/calls/interview-card/interview-card.component";
import {NumericToTenComponent} from './question-components/numeric-to-ten/numeric-to-ten.component';
import {BooleanComponent} from './question-components/boolean/boolean.component';
import {ScreenerQuestionComponent} from "./question-components/screener-question/screener-question.component";
import {MatrixMultiComponent} from './question-components/matrix-multi/matrix-multi.component';
import {MatrixSingleComponent} from './question-components/matrix-single/matrix-single.component';
import {OpenNumericComponent} from './question-components/open-numeric/open-numeric.component';
import {MultiOptionsComponent} from './question-components/multi-options/multi-options.component';
import {OptionsComponent} from './question-components/options/options.component';
import {MenuButtonComponent} from './components/buttons/menu-button/menu-button.component';
import {BaseModalComponent} from './modals/base/base-modal/base-modal.component';

import {
  CallScheduleCalendarModalComponent
} from './modals/call-schedule-calendar-modal/call-schedule-calendar-modal.component';
import {
  RescheduleCallCalendarModalComponent
} from './calendar/calendar-supporting-modals/reschedule-call-calendar-modal/reschedule-call-calendar-modal.component';
import {
  DetailsCalendarModalComponent
} from "./calendar/calendar-supporting-modals/details-calendar-modal/details-calendar-modal.component";
import {
  CancelCalendarModalComponent
} from './calendar/calendar-supporting-modals/cancel-calendar-modal/cancel-calendar-modal.component';
import {
  ConfirmCalendarModalComponent
} from './calendar/calendar-supporting-modals/confirm-calendar-modal/confirm-calendar-modal.component';
import {
  CreateCallCalendarModalComponent
} from './calendar/calendar-supporting-modals/create-call-calendar-modal/create-call-calendar-modal.component';

import {
  GenericTableInCardComponent
} from './components/generic-tables/generic-table-in-card/generic-table-in-card.component';
import {
  SegmentFeasibilitySelectorComponent
} from './components/segment-feasibility-selector/segment-feasibility-selector.component';
import {ShortEngagementStatusColorPipe} from './pipes/engagement-pipes/short-engagement-status-color.pipe';
import {ReferExpertModalComponent} from './modals/experts-modals/refer-expert-modal/refer-expert-modal.component';
import {AlertModalComponent} from './modals/general-modals/alert-modal/alert-modal.component';
import {PanelInvitationModalComponent} from "./modals/panel-invitation-modal/panel-invitation-modal.component";
import {
  InterviewCardForExpertComponent
} from './components/calls/interview-card-for-expert/interview-card-for-expert.component';
import {
  InterviewCardForClientComponent
} from './components/calls/interview-card-for-client/interview-card-for-client.component';
import {
  EditQuestionTemplateModalComponent
} from './modals/questions-modals/edit-question-template-modal/edit-question-template-modal.component';
import {
  AddQuestionsTemplateModalComponent
} from './modals/questions-modals/add-questions-template-modal/add-questions-template-modal.component';
import {AddQuestionComponent} from './components/add-questions/add-question.component';
import {AddQuestionsTemplateComponent} from './components/add-questions-template/add-questions-template.component';
import {
  ImportQuestionsTemplateComponent
} from './components/import-questions-template/import-questions-template.component';
import {
  ImportQuestionsTemplateModalComponent
} from './modals/questions-modals/import-questions-template-modal/import-questions-template-modal.component';
import {
  ProjectCreationModalComponent
} from './modals/projects-modals/project-creation-modal/project-creation-modal.component';
import {
  ViewEngagementModalComponent
} from './modals/engagements-modals/view-engagement-modal/view-engagement-modal.component';
import {
  PanelLandingPagePreviewModalComponent
} from './modals/panel-modals/panel-landing-page-preview-modal/panel-landing-page-preview-modal.component';
import {
  SetPanelLandingPageAsDefaultModalComponent
} from './modals/panel-modals/set-panel-landing-page-as-default-modal/set-panel-landing-page-as-default-modal.component';
import {
  GeneratePanelLandingPageLinkModalComponent
} from './modals/panel-modals/generate-panel-landing-page-link-modal/generate-panel-landing-page-link-modal.component';
import {
  CreatePanelLandingPageModalComponent
} from './modals/panel-modals/create-panel-landing-page-modal/create-panel-landing-page-modal.component';
import {
  PanelInvitePanelistsModalComponent
} from './modals/panel-modals/panel-invite-panelists-modal/panel-invite-panelists-modal.component';
import {
  ParticipantProfileModalComponent
} from './modals/experts-modals/participant-profile-modal/participant-profile-modal.component';
import {StringInputModalComponent} from './modals/general-modals/string-input-modal/string-input-modal.component';
import {NumberInputModalComponent} from './modals/general-modals/number-input-modal/number-input-modal.component';
import {TabDirective} from './components/tabs/tab.directive';
import {TextOnlyComponent} from './question-components/text-only/text-only.component';
import {VisualEngagementPipe} from "./pipes/engagement-pipes/visual-engagement.pipe";
import {VisualEngagementStatusPipe} from "./pipes/engagement-pipes/visual-engagement-status.pipe";
import {VisualShortEngagementPipe} from './pipes/engagement-pipes/visual-short-engagement.pipe';
import { DropdownSelectModalComponent } from './modals/general-modals/dropdown-select-modal/dropdown-select-modal.component';
import {CopyTextModalComponent} from "./modals/general-modals/copy-text-modal/copy-text-modal.component";
import {KeyValueArrayPipe} from "./pipes/key-value-array.pipe";
import { JoinCallComponent } from './components/calls/platform-details/join-call.component';
import { ListSelectionModalComponent } from './modals/general-modals/list-selection-modal/list-selection-modal.component';
import {
  AssignSubProjectStudyToTeamModalComponent
} from "./modals/sub-projects-modals/assign-sub-project-study-to-team-modal/assign-sub-project-study-to-team-modal.component";
import { CreateExternalCallModalComponent } from './calendar/calendar-supporting-modals/create-external-call-modal/create-external-call-modal.component';
import { AvatarsDetailModalComponent } from './components/picutres/avatars-detail-modal/avatars-detail-modal.component';
import { ExternalExpertCallScheduleModalComponent } from './modals/external-expert-call-schedule-modal/external-expert-call-schedule-modal.component';
import { ExternalProviderEngagementStatusPipe } from './pipes/engagement-pipes/external-provider-engagement-status.pipe';
import { ExternalProviderEngagementColorPipe } from './pipes/engagement-pipes/external-provider-engagement-color.pipe';
import { ExternalProviderCallModalComponent } from './modals/external-provider-call-modal/external-provider-call-modal.component';
import { FollowUpQuestionsModalComponent } from './modals/engagements-modals/follow-up-questions-modal/follow-up-questions-modal.component';
import { StepApproveSectionsComponent } from './components/step-accordion/step-approve-sections/step-approve-sections.component';
import { SectionApproveDirective } from './components/step-accordion/section-approve.directive';
import { ErrorHandlerModalComponent } from './modals/general-modals/error-handler-modal/error-handler-modal.component';
import {
  EditExperienceDialogComponent
} from "./components/expert-components/expert/edit-experience-dialog/edit-experience-dialog.component";
import { DateToFriendlyDatePipe } from './pipes/date-to-friendly-date.pipe';
import { ExternalProviderRescheduleCallModalComponent } from './modals/external-provider-reschedule-call-modal/external-provider-reschedule-call-modal.component';
import { NumericSelectionKeyToTextPipe } from './pipes/question-creation-pipes/numeric-selection-key-to-text.pipe';
import { FixCallProviderInformationModalComponent } from './modals/fix-call-provider-information-modal/fix-call-provider-information-modal.component';


@NgModule({
  declarations: [
    InterviewCardComponent,
    NotFoundComponent,
    BreadcrumbsComponent,
    ExpertComponent,
    DoesntExistYetComponent,
    ComplianceTextComponent,
    EditExperienceDialogComponent,
    EditDegreeDialogComponent,
    EditMainInfoDialogComponent,
    RatingBarComponent,
    RepeatPipe,
    ReversePipe,
    JsonDisplayPipe,
    OrgLogoComponent,
    ProjectImageComponent,
    UserPictureComponent,
    EmptyUserPictureComponent,
    DonutChartComponent,
    LocationsMapComponent,
    MatchRateComponent,
    CallsListComponent,
    ScreeningQuestionsViewerComponent,
    HistoryListComponent,
    SliderComponent,
    SiteFooterComponent,
    GenericPaginatorComponent,
    GenericTableComponent,
    CallsScheduledDoneListsComponent,
    EngagementStatusComponent,
    StatusItemComponent,
    CustomTooltipComponent,
    ToolTipDirective,
    ToggleListComponent,
    ProgressBarComponent,
    GeographyDisplayPipe,
    SurvivalDisplayPipe,
    DragAndDropFileComponent,
    FileNameDisplayPipe,
    NumberedProgressBarComponent,
    LoginWithLinkedinComponent,
    TimezoneAwareDateDisplayPipe,
    TimezoneAwareTimeDisplayPipe,
    LanguageSelectorImagePipe,
    TaskTrackerArgumentVisualizePipe,
    PascalCaseToReadablePipe,
    LatestQuestionChangeLogPipe,
    CapitalizeFirstLetterPipe,
    GetPercentOutOfWholePipe,
    SkeletionTableComponent,
    FormLocationSelectorComponent,
    NavTabComponent,
    AvatarComponent,
    StepProgressComponent,
    ConfirmationModalComponent,
    StringInputModalComponent,
    NumberInputModalComponent,
    GetTeamMembersFromSubProjectPipe,
    BadgeComponent,
    SignatureFileUploadModalComponent,
    RelativeTimePipe,
    CreateSubProjectModalComponent,
    TextToHtmlPipe,
    KababCaseToReadableTextPipe,
    CalendarViewComponent,
    CalendarSupportingModalsComponent,
    RadialProgressComponent,
    AlertBannerComponent,
    BaseComponent,
    BasePageComponent,
    PaginationComponent,
    LoaderComponent,
    FileUploadModalComponent,
    QuestionMarkTooltipComponent,
    InterviewCardComponent,
    OutlinedIconButtonComponent,
    VisualEngagementPipe,
    MenuButtonComponent,
    BaseModalComponent,
    NumericToTenComponent,
    BooleanComponent,
    ScreenerQuestionComponent,
    MatrixMultiComponent,
    MatrixSingleComponent,
    OpenNumericComponent,
    MultiOptionsComponent,
    OptionsComponent,
    VisualEngagementStatusPipe,
    GenericTableInCardComponent,
    SegmentFeasibilitySelectorComponent,
    ShortEngagementStatusColorPipe,
    ReferExpertModalComponent,
    AlertModalComponent,
    PanelInvitationModalComponent,
    InterviewCardForExpertComponent,
    InterviewCardForClientComponent,
    ParticipantProfileModalComponent,
    EditQuestionTemplateModalComponent,
    AddQuestionsTemplateModalComponent,
    AddQuestionComponent,
    AddQuestionsTemplateComponent,
    ImportQuestionsTemplateComponent,
    ImportQuestionsTemplateModalComponent,
    ProjectCreationModalComponent,
    ViewEngagementModalComponent,
    CallScheduleCalendarModalComponent,
    RescheduleCallCalendarModalComponent,
    DetailsCalendarModalComponent,
    CancelCalendarModalComponent,
    ConfirmCalendarModalComponent,
    CreateCallCalendarModalComponent,
    PanelLandingPagePreviewModalComponent,
    SetPanelLandingPageAsDefaultModalComponent,
    GeneratePanelLandingPageLinkModalComponent,
    CreatePanelLandingPageModalComponent,
    PanelInvitePanelistsModalComponent,
    TabDirective,
    TextOnlyComponent,
    VisualShortEngagementPipe,
    DropdownSelectModalComponent,
    CopyTextModalComponent,
    KeyValueArrayPipe,
    JoinCallComponent,
    ListSelectionModalComponent,
    AssignSubProjectStudyToTeamModalComponent,
    CreateExternalCallModalComponent,
    AvatarsDetailModalComponent,
    ExternalExpertCallScheduleModalComponent,
    ExternalProviderEngagementStatusPipe,
    ExternalProviderEngagementColorPipe,
    ExternalProviderCallModalComponent,
    FollowUpQuestionsModalComponent,
    ErrorHandlerModalComponent,
    StepApproveSectionsComponent,
    SectionApproveDirective,
    DateToFriendlyDatePipe,
    ExternalProviderRescheduleCallModalComponent,
    NumericSelectionKeyToTextPipe,
    FixCallProviderInformationModalComponent
  ],
  exports: [
    NumericSelectionKeyToTextPipe,
    DateToFriendlyDatePipe,
    ExternalProviderEngagementColorPipe,
    ExternalProviderEngagementStatusPipe,
    CopyTextModalComponent,
    ParticipantProfileModalComponent,
    VisualEngagementStatusPipe,
    VisualEngagementPipe,
    InterviewCardComponent,
    KababCaseToReadableTextPipe,
    TextToHtmlPipe,
    ConfirmationModalComponent,
    GetTeamMembersFromSubProjectPipe,
    GetPercentOutOfWholePipe,
    CapitalizeFirstLetterPipe,
    LatestQuestionChangeLogPipe,
    PascalCaseToReadablePipe,
    TaskTrackerArgumentVisualizePipe,
    LanguageSelectorImagePipe,
    LoginWithLinkedinComponent,
    BreadcrumbsComponent,
    ExpertComponent,
    DoesntExistYetComponent,
    RatingBarComponent,
    OrgLogoComponent,
    ReversePipe,
    JsonDisplayPipe,
    UserPictureComponent,
    ProjectImageComponent,
    DonutChartComponent,
    LocationsMapComponent,
    MatchRateComponent,
    CallsListComponent,
    ScreeningQuestionsViewerComponent,
    HistoryListComponent,
    ComplianceTextComponent,
    SliderComponent,
    SiteFooterComponent,
    EmptyUserPictureComponent,
    GenericPaginatorComponent,
    GenericTableComponent,
    CallsScheduledDoneListsComponent,
    EngagementStatusComponent,
    ToggleListComponent,
    SurvivalDisplayPipe,
    GeographyDisplayPipe,
    FileNameDisplayPipe,
    NumberedProgressBarComponent,
    TimezoneAwareDateDisplayPipe,
    TimezoneAwareTimeDisplayPipe,
    DragAndDropFileComponent,
    SkeletionTableComponent,
    FormLocationSelectorComponent,
    NavTabComponent,
    AvatarComponent,
    StepProgressComponent,
    BadgeComponent,
    SignatureFileUploadModalComponent,
    RelativeTimePipe,
    CreateSubProjectModalComponent,
    CalendarViewComponent,
    RadialProgressComponent,
    AlertBannerComponent,
    BaseComponent,
    BasePageComponent,
    ProgressBarComponent,
    PaginationComponent,
    LoaderComponent,
    FileUploadModalComponent,
    QuestionMarkTooltipComponent,
    OutlinedIconButtonComponent,
    VisualEngagementPipe,
    InterviewCardComponent,
    MenuButtonComponent,
    BaseModalComponent,
    NumericToTenComponent,
    BooleanComponent,
    ScreenerQuestionComponent,
    VisualEngagementStatusPipe,
    GenericTableInCardComponent,
    SegmentFeasibilitySelectorComponent,
    ShortEngagementStatusColorPipe,
    InterviewCardForClientComponent,
    InterviewCardForExpertComponent,
    AddQuestionComponent,
    TabDirective,
    KeyValueArrayPipe,
    StepApproveSectionsComponent,
    SectionApproveDirective
  ],
  imports: [
    NgxMaterialTimepickerModule,
    CommonModule,
    RouterModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    // MatTableModule,
    DragDropModule,
    CalendarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ClipboardModule,
    LottieModule,
    NgIconComponent,
    TranslateModule,
    NgSelectModule,
    NgxSliderModule,
    NgxSliderModule,
    MatTableModule,
  ],
  providers:
    [
      // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ]
})

export class SharedModule {
}
