import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {LoginGuard} from './shared/auth/login-guard.service';
import {NamingConventionInterceptor} from './shared/interceptors/naming-convention-interceptor';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {ClientGuard} from './shared/auth/client-guard.service';
import {ExpertGuard} from './shared/auth/expert-guard.service';
import {OrgManagerGuard} from './shared/auth/org-manager-guard.service';
import {AdminGuard} from './shared/auth/admin-guard.service';
import {FinanceAdminGuard} from './shared/auth/finance-admin-guard.service';
import {SharedModule} from './shared/shared.module';
import {CodeInputModule} from 'angular-code-input';
import {NgApexchartsModule} from "ng-apexcharts";
import {BrowserModule} from '@angular/platform-browser';
import {LottieModule} from 'ngx-lottie';
import {NgIconsModule} from '@ng-icons/core';
import {featherAirplay} from '@ng-icons/feather-icons';
import {heroUsers} from '@ng-icons/heroicons/outline';
import {bootstrapArrowDown, bootstrapBoxArrowDown, bootstrapInfoCircle} from '@ng-icons/bootstrap-icons';
import {
  bootstrapBuilding, bootstrapSun, bootstrapMoonFill, bootstrapCircleHalf,
  bootstrapChevronLeft, bootstrapQuestionCircle, bootstrapListTask,
  bootstrapChevronRight, bootstrapExclamationCircle,
  bootstrapLinkedin, bootstrapActivity, bootstrapClock, bootstrapExclamationOctagon,
  bootstrapPerson, bootstrapRecord, bootstrapPeople, bootstrapSearch,
} from '@ng-icons/bootstrap-icons';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {GlobalErrorHandler} from "./shared/error-handler/global-error-handler";
import {ErrorHandlingInterceptor} from "./shared/interceptors/error-handling-interceptor";

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  // {
  //   path: 'status',
  //   component: DashboardLayoutComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     {path: '', component: StatusComponent, pathMatch: 'full', data: {routeName: 'status'}},
  //   ],
  // },

  {
    path: 'redirect',
    loadChildren: () => import('./redirect/redirect.module').then(m => m.RedirectModule)
  },
  {
    path: 'panel-landing',
    data: {allowDashboard: false},
    loadChildren: () => import('./panel-landing/panel-landing.module').then(m => m.PanelLandingModule)
  },
  {
    path: 'external-provider-zone',
    loadChildren: () => import('./external-provider-zone/external-provider-zone.module').then(m => m.ExternalProviderZoneModule)
  },

  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },

  {
    path: 'expert-registration',
    loadChildren: () => import('./expert-registration/expert-registration.module').then(m => m.ExpertRegistrationModule)
  },
  {
    path: 'public',
    loadChildren: () => import('./public-pages/public-pages.module').then(m => m.PublicPagesModule)
  },

  {path: '**', component: NotFoundComponent, data: {routeName: 'not_found'}},
];


const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

export function playerFactory() {
  return import('lottie-web');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgIconsModule.withIcons({
      bootstrapArrowDown,
      featherAirplay,
      heroUsers,
      bootstrapInfoCircle,
      bootstrapLinkedin,
      bootstrapBuilding,
      bootstrapSearch,
      bootstrapExclamationCircle,
      bootstrapMoonFill,
      bootstrapExclamationOctagon,
      bootstrapClock,
      bootstrapActivity,
      bootstrapPerson,
      bootstrapChevronRight,
      bootstrapChevronLeft,
      bootstrapRecord,
      bootstrapPeople,
      bootstrapBoxArrowDown,
      bootstrapSun,
      bootstrapCircleHalf,
      bootstrapListTask,
      bootstrapQuestionCircle
    }),
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, routerOptions),
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot(),
    SharedModule,
    CodeInputModule,
    NgApexchartsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LottieModule.forRoot({player: playerFactory})
  ],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NamingConventionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    AuthGuard,
    LoginGuard,
    ClientGuard,
    ExpertGuard,
    FinanceAdminGuard,
    OrgManagerGuard,
    AdminGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
