import { ErrorHandler, Injectable } from '@angular/core';
import {SentryErrorHandler} from "@sentry/angular-ivy";

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler {
  constructor() {
    super({
      showDialog: true,
    });
  }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk .*failed.*[.js\\)]/;
    if (chunkFailedMessage.test(error.message)) {
      if (confirm('New version available. Load New Version?')) {
        window.location.reload(); 
      }
    } else {
      super.handleError(error);
    }
  }
}
