<div class="modal">
  <label class="modal-overlay"></label>
  <div class="modal-content flex flex-col gap-5 alert-modal-body">
    <label (click)="close(null)" class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
    <h2 class="text-xl">{{data.title}}</h2>
    <span>{{data.message}}</span>
    <div class="flex gap-3">
      <label (click)="close(null)" class="btn btn-primary btn-block btn-sm">{{data?.actionName || 'Confirm'}}</label>
    </div>
  </div>
</div>
