import {Injectable} from '@angular/core';
import {Moment} from 'moment';
import {DateUtilsService} from './date-utils.service';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SiteRefreshService {
  latestLoadMoment: Moment = null;
  readonly MINUTES_TO_REFRESH = 180;

  constructor(private router: Router, public dateUtilsService: DateUtilsService) {
    this.latestLoadMoment = this.dateUtilsService.now();
  }

  public registerRouter(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkReload();
      }
    });
  }

  checkReload(): void {
    const now = this.dateUtilsService.now();
    if (this.dateUtilsService.getDiffMinutes(this.latestLoadMoment, now) >= this.MINUTES_TO_REFRESH) {
      location.reload();
    }
  }
}
