import {Component, OnDestroy} from '@angular/core';
import {BaseModalComponent} from "../../base/base-modal/base-modal.component";

export interface AlertModalInputData {
  title: string;
  message: string;
  actionName?: string;
  showCopyButton?: boolean;
}

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent extends BaseModalComponent<AlertModalInputData, null> {

}
