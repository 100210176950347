import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {combineLatest, filter, Observable} from 'rxjs';
import {RoutingService} from '../services/routing.service';
import {map} from "rxjs/operators";

@Injectable()
export class LoginGuard  {

  constructor(private routingService: RoutingService, private _authService: AuthenticationService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this._authService.isAuth$,
      this._authService.authUser$
    ]).pipe(
      filter(([isAuth, user]) => isAuth !== null), // Proceed only if isAuth is not null
      map(([isAuth, user]) => {
        if (isAuth) {
          if (user && user.isAdmin) {
            this.routingService.navigateToAdminSubProjects();
            return false;
          }
          this.routingService.navigateToRoot();
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
