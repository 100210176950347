import {catchError} from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {ModalsService} from "../modals/modals-service";
import {ErrorHandlerModalComponent} from "../modals/general-modals/error-handler-modal/error-handler-modal.component";

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  constructor(private modalsService:ModalsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.modalsService.openModal(ErrorHandlerModalComponent,{error});
        return of(null);
      })
    );
  }
}
