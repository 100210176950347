import {Component} from '@angular/core';
import {BaseModalComponent} from "../../base/base-modal/base-modal.component";
import {FormControl, Validators} from '@angular/forms';

export interface StringInputModalInput {
  header: string;
  text: string;
  subText?: string;
  cancelActionName?: string;
  confirmActionName?: string;
  defaultValue?: string;
  allowEmpty?: boolean;
  placeholder?: string;
  isTextArea?: boolean;
  textAreaRows?: number;
}

@Component({
  selector: 'app-string-input-modal',
  templateUrl: './string-input-modal.component.html',
  styleUrls: ['./string-input-modal.component.scss']
})
export class StringInputModalComponent extends BaseModalComponent<StringInputModalInput, string> {
  public cancelActionName: string;
  public confirmActionName: string;
  public formCtrl = new FormControl('');
  placeholder: string = '';

  ngOnInit(): void {
  }

  async onOpen() {
    this.cancelActionName = this.data.cancelActionName || 'Cancel';
    this.confirmActionName = this.data.confirmActionName || 'Confirm';
    if (this.data.defaultValue) {
      this.formCtrl.setValue(this.data.defaultValue);
    }
    if (!this.data.allowEmpty) {
      this.formCtrl.setValidators([Validators.required]);
    }
    this.placeholder = this.data.placeholder || '';
  }

  public async onSubmit() {
    if (!this.formCtrl.valid) {
      return
    }
    await this.close(this.formCtrl.value);
  }

}
