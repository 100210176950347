import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
interface TimeZoneResponse {
  timezone: string;
}
@Injectable({
  providedIn: 'root'
})
export class TimezoneApiService {
  constructor(private http: HttpClient) {
  }

  async updateMyTimezone(): Promise<void> {
    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await this.http.post<void>(`/api/users/my/timezone/`, {
      timezoneName
    }).toPromise();
  }

  async updateMyTimezoneBySecretId(expertId: number, secretId: string): Promise<void> {
    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await this.http.post<void>(`/api/users/my/timezone/${expertId}/by-secret-id/`, {
      secretId,
      timezoneName
    }).toPromise();
  }
  getMyTimezone(): Promise<TimeZoneResponse> {
    return this.http.get<TimeZoneResponse>('/api/users/my/timezone/').toPromise();
  }
}
