export enum ExpertOnboardingSteps{
  NONE= 'none',
  WELCOME = 'welcome',
  LINKEDIN = 'linkedin',
  REGISTRATION_METHODS = 'registration_methods',
  FULL_NAME = 'full_name',
  EMAIL = 'email',
  EMAIL_CONFIRMATION = 'email_confirmation',
  CREATE_PASSWORD = 'create_password',
  FINAL_SETUP = 'final_setup',
  TERM_SERVICES = 'term_services'
}

export type ExpertOnboardingStepperKey = keyof typeof expertOnboardingStepTypes
type ExpertOnboardingStepperType = { [key:string]: Partial<{ [key in ExpertOnboardingSteps]: number }> };

export const expertOnboardingStepTypes:ExpertOnboardingStepperType = {
  inviteBasedOnboardingStepNumbers: {
    [ExpertOnboardingSteps.REGISTRATION_METHODS]: 1,
    [ExpertOnboardingSteps.FULL_NAME]: 2,
    [ExpertOnboardingSteps.EMAIL]: 3,
    [ExpertOnboardingSteps.EMAIL_CONFIRMATION]: 4,
    [ExpertOnboardingSteps.CREATE_PASSWORD]: 5
  },
  inviteBasedLinkedInOnboardingStepNumbers: {
    [ExpertOnboardingSteps.REGISTRATION_METHODS]: 1,
    [ExpertOnboardingSteps.TERM_SERVICES]: 2,
  },
  selfRegistrationBasedOnboardingStepNumbers: {
    [ExpertOnboardingSteps.LINKEDIN]: 1,
    [ExpertOnboardingSteps.REGISTRATION_METHODS]: 2,
    [ExpertOnboardingSteps.FULL_NAME]: 3,
    [ExpertOnboardingSteps.EMAIL]: 4,
    [ExpertOnboardingSteps.EMAIL_CONFIRMATION]: 5,
    [ExpertOnboardingSteps.CREATE_PASSWORD]: 6
  }
}

