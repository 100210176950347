import {AfterContentInit, AfterViewInit, Component, Injector, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {EventsAnalyticsService} from './shared/services/events-analytics.service';
import {SiteRefreshService} from './shared/services/site-refresh.service';
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {TranslatorControllerService} from "./shared/states/global-state-services/translator-controller.service";
import {ModalsService} from './shared/modals/modals-service';

export let AppInjector: Injector;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  loading = true;
  loadingElement: HTMLElement;
  @ViewChild('modalsContainer', {read: ViewContainerRef}) modalsContainer: ViewContainerRef;

  constructor(private eventsAnalyticsService: EventsAnalyticsService,
              private siteRefreshService: SiteRefreshService,
              private router: Router,
              private translatorControllerService: TranslatorControllerService,
              private injector: Injector,
              private modalsService: ModalsService,
  ) {
    this.eventsAnalyticsService.registerRouter();
    this.siteRefreshService.registerRouter();
    this.translatorControllerService.init();
    AppInjector = this.injector;
  }

  ngAfterViewInit(): void {
    this.modalsService.init(this.modalsContainer);
  }

  ngOnInit(): void {
    this.loadingElement = document.getElementById("loading-container");
    this.hideLoading();

    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.showLoading();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.hideLoading();
      }
    });
  }

  private showLoading(): void {
    this.loadingElement.style.display = 'flex';
  }

  private hideLoading(): void {
    this.loadingElement.style.display = 'none';
  }

}
