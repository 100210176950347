import {ComponentRef, Injectable, Type, ViewContainerRef} from '@angular/core';
import {BaseModalComponent} from './base/base-modal/base-modal.component';
import {ConfirmationModalComponent, ConfirmationModalInput} from './general-modals/confirmation-modal/confirmation-modal.component';
import {ReferExpertModalComponent} from './experts-modals/refer-expert-modal/refer-expert-modal.component';
import {AlertModalComponent, AlertModalInputData} from './general-modals/alert-modal/alert-modal.component';
import {
  StringInputModalComponent,
  StringInputModalInput
} from './general-modals/string-input-modal/string-input-modal.component';
import {
  NumberInputModalComponent,
  NumberInputModalInput
} from './general-modals/number-input-modal/number-input-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  modalsContainer: ViewContainerRef;

  init(modalsContainer: ViewContainerRef) {
    this.modalsContainer = modalsContainer;
  }

  public async openModal<T, S>(modalCls: Type<BaseModalComponent<T, S>>, data: T): Promise<S> {
    let modalRef = this.modalsContainer.createComponent(modalCls);
    this.setRefAsVisible(modalRef);
    await modalRef.instance.init(data);
    return new Promise((resolve) => {
      modalRef.instance.setRefAndResolve(modalRef, resolve);
    });
  }

  public closeAllModals() {
    this.modalsContainer.clear();
  }

  private setRefAsVisible(modalRef: ComponentRef<BaseModalComponent<any, any>>) {
    const div = modalRef.location.nativeElement.children[0];
    div.style.visibility = "visible";
    div.style.opacity = "1";
  }

  public async getBooleanFromConfirmationDialog(data: ConfirmationModalInput): Promise<boolean> {
    return await this.openModal<ConfirmationModalInput, boolean>(ConfirmationModalComponent, data);
  }

  public async openAlertModal(data: AlertModalInputData): Promise<void> {
    await this.openModal<AlertModalInputData, null>(AlertModalComponent, data);
  }

  public async getStringFromInputModal(data: StringInputModalInput): Promise<string> {
    return await this.openModal<StringInputModalInput, string>(StringInputModalComponent, data);
  }

  public async getNumberFromInputModal(data: NumberInputModalInput): Promise<number> {
    return await this.openModal<NumberInputModalInput, number>(NumberInputModalComponent, data);
  }
}
