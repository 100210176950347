import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseModalComponent} from "../../base/base-modal/base-modal.component";

export interface ConfirmationModalInput {
  header: string;
  text: string;
  cancelActionName?: string;
  confirmActionName?: string;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent extends BaseModalComponent<ConfirmationModalInput, boolean> {
  public cancelActionName: string;
  public confirmActionName: string;

  ngOnInit(): void {
    this.cancelActionName = this.data.cancelActionName || 'Cancel';
    this.confirmActionName = this.data.confirmActionName || 'Confirm';
  }

}
