import {Injectable} from '@angular/core';
import {RoleGuard} from './role-guard.service';
import {LoginResponse} from '../models/users/login-response';

@Injectable()
export class AuthGuard extends RoleGuard {
  userHasRole(loginResponse: LoginResponse): boolean {
    return loginResponse.isAuth;
  }
}
